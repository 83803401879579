import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
// import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#F08300';
const footerRelatedLinks = [
	{
		title: 'Crowsnest Properties Branding',
		url: '/fr/projets/crowsnest-properties-branding/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectAckTypoPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				image_1: file(
					relativePath: {
						eq: "projects/terre-des-hommes-campagne-orange-2/SUPT_TDH_Interface-1.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/terre-des-hommes-campagne-orange-2/SUPT_TDH_Interface-2.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/terre-des-hommes-my-orange/',
					lang: 'en',
				},
			]}
			title="Terre des Hommes, My orange - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Terre des Hommes, My orange"
				video={{
					id: '3745d1e721a246ee82db38697124f5b4',
					alt: '',
				}}
			/>

			<section className="grid grid-content" aria-label="Projet">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							La version gamifiée de la campagne des oranges de
							Terre des hommes.
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Research',
								'Concept',
								'UX/UI',
								'Motion Design',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Le contexte du projet</h3>
						<p>
							En 2020, les restrictions sanitaires ont mis à mal
							la fameuse vente d’oranges dans la rue organisée par
							Terre des hommes. Nous avions, durant la COVID-19,
							imaginé une version digitale de la campagne des
							oranges de Terre des hommes. En 2022, l’ONG avait le
							désir de lancer une nouvelle itération du jeu avec
							un nouveau concept ludique.
						</p>

						<h3>Notre réponse</h3>
						<p>
							Cette campagne digitale de gamification fait entrer
							le visiteur dans un univers riche d’illustrations
							animées qui présente le travail de l’ONG sur le
							terrain. Chaque visiteur est invité à trouver le
							maximum d’oranges possible dans un laps de temps
							réduit. Plus la récolte d’oranges est abondante,
							plus l’impact sera important sur le terrain.
						</p>
						<p>
							Les illustrations pour l’édition 2022 ont été
							réalisées en collaboration avec la talentueuse
							Catherine Pearson.
						</p>
					</div>
				</div>

				<Video
					className="col-full"
					src="cc96681ebc287badca01a8daa6c0c079"
					alt=""
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Video src="10d5f84f4db3c3ca961fdbf383eed14f" alt="" />
					<Image {...data.image_1.childImageSharp} alt="Interface" />
				</Gallery>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Sensibiliser sur un ton léger et positif</h3>
						<p>
							Une fois le jeu terminé, l’utilisateur peut faire
							don de son panier, c’est-à-dire offrir le montant
							total des oranges récoltées. Il peut également
							personnaliser le nombre d’oranges lui permettant de
							choisir la somme exacte qu’il souhaite offrir.
						</p>
						<p>
							Pour encourager le don, nous avons démontré quelles
							bénéfices concrêt ce don allait généré sur le
							terrain. Il ne lui reste plus qu’à passer à
							l’action.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_2.childImageSharp}
							alt="Interface"
						/>
						<Video
							fluid={[
								{
									src: '7c1da20247af3e1d63361ebc944bafd0',
								},
								{
									src: 'fac5fbe9911e6778124aba3d72e0cfc3',
									minWidth: 768,
								},
							]}
							alt=""
							className="grid-spacing"
						/>
					</div>
				</Column>
			</section>
		</Layout>
	);
};

export default ProjectAckTypoPage;
